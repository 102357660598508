export enum RoleEnum {
    Admin = 1,
    Manager = 2,
    Employee = 3
}

export enum PermissionEnum {
    CompanyManage = 1,
    UsersView = 2,
    UsersEdit = 3,
    UsersDelete = 4,
    ManagersEdit = 5,
    ManagePayment = 6,
    ManageAccounts = 7,
}

export interface User {
    id: number,
    companyId?: number,
    firstName: string,
    title?: string,
    lastName: string,
    fullName?: string,
    phone?: string,
    email: string,
    picture?: string,
    active?: boolean,
    roles?: RoleEnum[],
    permissions?: PermissionEnum[],
    isProjectManager?: boolean,
    companyName?: string,
    lastLogin?: Date | string,
    country?: string,
    currency?: Currency,

    projects?: number[],
}

export interface Place {
    unit?: string,
    street: string,
    city: string,
    province: string,
    country: string,
    postalCode: string
    fullAddress: string,
}


export enum CompanyType {
    sole_proprietorship = "sole_proprietorship",
    partnership = "partnership",
    llp = "llp",
    llc = "llc",
    corporation = "corporation",
    corporation_pvt = "corporation_pvt",
    non_profit = "non_profit",
    other = "other",
}

export interface Company {
    id: number,
    locationId: number,
    name: string,
    type: CompanyType,
    companyAddress: Place,
    phone: string,
    active: boolean,
}

export enum NotificationType {
    ExpenseClaimResolved
}

export interface NotificationInfo {
    userName?: string,
    id?: number,
    amount?: number,
    status?: number,
    from?: Date | string,
    to?: Date | string,
    vendor?: string,
}

export interface Notification {
    id: number,
    created: Date | string,
    read: boolean,
    type: NotificationType,
    info: NotificationInfo,
}

export interface Device {
    id: number,
    companyId: number,
    pushEndpoint: string,
    pushP256DH: string,
    pushAuth: string,
    enabled: boolean
}

export interface UserFilter {
    searchText: string,
    roles: number[]
}

export enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
}

export interface DateRange {
    start: Date,
    end: Date,
}

export interface EndUserInfo {
    ip?: string,
    browser: string,
    language: string,
    mobile: boolean,
    platform: string
}

export interface UserLog {
    id: number,
    userId: number,
    type: 'Login' | 'Logout' | 'LoadUserInfo',
    info: EndUserInfo,
    created: Date,

    name: string,
    role?: number,
}

export type SortDirection = "ascending" | "descending";

export interface SortConfig<T = any> {
    key: keyof T,
    direction: SortDirection
}

export interface CompanySetting {
    id?: number,
    trackLocation: boolean,
    yearEndMonth: number,
    yearEndDay: number,
    currency: Currency
}

export interface ProjectUser {
    id?: number,
    userId: number,
    projectId?: number,
    fullName: string,
    user: User,
    created?: Date | string,
}

export enum Status {
    Pending,
    Canceled,
    Accepted,
    Rejected
}

export interface LocationCoordinate {
    id: number,
    sessionId: number,
    latitude: number,
    longitude: number,
    altitude?: number,
    accuracy?: number,
    altitudeAccuracy?: number,
    heading?: number,
    speed?: number,
    created?: string | Date
}

export enum Platforms {
    Desktop,
    MobileBrowser,
    MobileApp
}

export interface Session {
    id: number,
    userId: number,
    start: Date | string,
    end?: Date | string,
    platform?: Platforms,
    duration?: number,
    created: Date | string,
    fullName?: string,
    user?: User,
    locationCoordinates?: LocationCoordinate[]
}

export interface Project {
    id: number,
    managerId: number,
    name: string,
    description: string,
    manager: string,
    active: boolean,
    created: Date | string,
}

export interface ImportError {
    row: number,
    invalidFields: string[]
}

export enum ExpensePayment {
    CompanyFunds = 1,
    PersonalFunds
}

export interface ExpenseClaim {
    id: number,
    userId: number,
    currency: Currency,
    subtotal: number,
    taxes: number,
    total: number,
    paid: boolean,
    accountId: number,
    companyTaxId: number,
    creditCardId?: number,
    paymentMethod: ExpensePayment,
    vendor: string,
    description: string,
    receiptUrl?: string,
    status: Status,
    note?: string,
    expenseDate: Date | string,
    start?: Date | string,
    end?: Date | string,
    created?: Date | string,
    createdBy?: number,
    lastModified?: Date | string,
    lastModifiedBy?: number,

    user?: User,
    account?: Account,
    companyTax?: CompanyTax,
    creditCard?: CreditCard,
    projectExpenses?: ProjectExpense[]
}

export enum Products {
    HR = 1,
    POS,
    Custom
}

export interface Subscription {
    id: number,
    companyId: number,
    company: string,
    start: Date | string,
    end: Date | string,
    created: Date | string,
}

export interface Invoice {
    id?: number,
    companyId?: number,
    subscriptionId: number,
    subtotal: number,
    discount: number,
    taxes: number,
    total: number,
    users: number,
    price: number,
    company: Company,
    companyName: string,
    receiptId: string,
    created: Date | string,

    subscription?: Subscription
}

export interface Discount {
    id: number,
    name: string,
    code: string,
    rate: number,
    maxUsages: number,
    active: boolean,
    created: string | Date,
    createdBy: number,
}

export interface SelectOption {
    value: number;
    label: string;
}

export interface VaultToken {
    id: number,
    maskedPan: string,
    expiryDate: string | Date,
    created: string | Date,
}

export enum AccountType {
    Mileage = 1,
    DailyIncidentals,
    Other,
}

export interface Account {
    id?: number,
    name: string,
    description?: string,
    type: AccountType,
    active: boolean,
    created: string | Date,
    createdBy: number,
}

export enum Currency {
    USD = 1,
    CAD,
    EUR,
    GBP,
    AUD,
    AED,
    SAR,
    PKR,
    INR,
    MXN,
    CRC,
    JMD,
    BSD
}

export interface CreditCard {
    id?: number;
    name: string;
    maskedPan: string;
    active: boolean;
    created: string | Date,
    createdBy: number,
}

export interface ProjectExpense {
    id?: number,
    projectId: number,
    projectName?: string,
    expenseClaimId?: number,
    rate: number,
    amount?: number,
    created?: string | Date,
    createdBy?: number,

    project?: Project
}

export enum Month {
    Jan = 1,
    Feb,
    Mar,
    Apr,
    May,
    Jun,
    Jul,
    Aug,
    Sep,
    Oct,
    Nov,
    Dec
}

export enum DistanceUnit {
    KM = 1,
    Mile,
}

export interface MileageAllowance {
    id?: number;
    year: number;
    rate: number;
    distance: number;
    unit: DistanceUnit;
    secondaryRate: number;
    created?: string | Date,
    createdBy?: number,
}

export interface DailyAllowance {
    id?: number;
    year: number;
    amount: number;
    created?: string | Date,
    createdBy?: number,
}

export interface CompanyTax {
    id?: number;
    name: string;
    rate: number;
    active: boolean;
    created?: string | Date,
    createdBy?: number,
}


export interface ReportFilter {
    days: number,
    start: Date,
    end: Date
}