import { CompanyType, Status, RoleEnum, User, AccountType, ExpensePayment, Place, DistanceUnit, MileageAllowance, Currency } from '../models'
import { TFunction } from 'i18next';
import { getUserInfo, isAuthenticated } from './auth';
import { getCurrencySymbol, getCurrencySymbolByCountry } from './countries';

export const getServerUrl = () => window.location.href.indexOf('3000') > -1 ? "https://localhost:44377" : ''

export const getQueryStringValue = (key: string) => {
    const params = new URLSearchParams(window.location.search)
    const value = params.get(key);
    return value;
};

export const getQueryStringParam = (key: string) => {
    const value = getQueryStringValue(key);

    let str = '';
    if (value) str = atob(value);

    return str;
};

export const getQueryIntParam = (key: string) => {
    const value = getQueryStringValue(key);

    let int = 0;
    if (value) int = Number.parseInt(atob(value));

    return int;
};

export const addQueryParam = (url: string, key: string, value: number) => `${url}?${key}=${btoa(value.toString())}`;

export const stripHtml = (str: string) => str.replace(/(<([^>]+)>)/gi, "").replace("&nbsp;", " ");

export const truncate = (str: string, max: number) => str && str.length > max ? `${str.substring(0, max)} ...` : str;

export const truncateHtml = (str: string, max: number) => {
    const strippedstr = stripHtml(str);
    return strippedstr && strippedstr.length > max ? `${strippedstr.substring(0, max)} ...` : strippedstr;
};

export const setPageTitle = (title: string) => document.title = ` ${title} - Expense Ledger`;

export const padNumber = (num: number, size = 2) => {
    var s = num.toString()
    while (s.length < size) s = "0" + s
    return s
};

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

let mobileMode = false;

(function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) {
        mobileMode = true;
    }
})(navigator.userAgent || navigator.vendor || (window as any).opera);

export const isMobileMode = () => mobileMode;

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email: string) => emailRegEx.test(email);

// eslint-disable-next-line
export const urlRegEx = /(((https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/gi

export const compareValues = (key: string, order: 'desc' | 'asc' = 'asc', secondaryKey: string | null = null) => {
    return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            console.warn("Not all sorting objects have key: ", key)
            return 0;
        }

        let primaryA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
        let primaryB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

        if (secondaryKey !== null && (typeof a[key] === 'string' || typeof a[key] === a[secondaryKey])) {
            primaryA += (typeof a[secondaryKey] === 'string') ? a[secondaryKey].toUpperCase() : a[secondaryKey];
            primaryB += (typeof b[secondaryKey] === 'string') ? b[secondaryKey].toUpperCase() : b[secondaryKey];
        }

        let comparison = 0;
        if (primaryA > primaryB) {
            comparison = 1;
        } else if (primaryA < primaryB) {
            comparison = -1;
        }

        return (order === 'desc') ? (comparison * -1) : comparison;
    };
}

export const getFullName = (user: User) => {
    if (!user) return '';
    return `${user.firstName} ${user.lastName}`;
}

export const getRoleLabel = (t: TFunction, role: RoleEnum) => {
    switch (role) {
        case RoleEnum.Admin:
            return t("admin");
        case RoleEnum.Manager:
            return t("manager");
        case RoleEnum.Employee:
            return t("employee");

        default:
            return "";
    }
}

export const getCompanyTypeLabel = (t: TFunction, type: CompanyType) => {
    switch (type) {
        case CompanyType.sole_proprietorship:
            return t("soleProprietorship");
        case CompanyType.partnership:
            return t("partnership");
        case CompanyType.llp:
            return t("llp");
        case CompanyType.llc:
            return t("llc");
        case CompanyType.corporation:
            return t("corporation");
        case CompanyType.corporation_pvt:
            return t("corporationPvt");
        case CompanyType.non_profit:
            return t("nonProfit");
        default:
            return "";
    }
}

export const getStatusLabel = (t: TFunction, status: Status) => {
    switch (status) {
        case Status.Pending:
            return t("pending");
        case Status.Canceled:
            return t("canceled");
        case Status.Accepted:
            return t("approved");
        case Status.Rejected:
            return t("rejected");
        default:
            return "";
    }
}

export const getStatusColor = (status: Status) => {
    switch (status) {
        case Status.Pending:
            return "gray";
        case Status.Canceled:
            return "yellow";
        case Status.Accepted:
            return "green";
        case Status.Rejected:
            return "red";
        default:
            return "gray";
    }
}

export const getAccountTypeLabel = (t: TFunction, type: AccountType) => {
    switch (type) {
        // case AccountType.Mileage:
        //     return t("mileage");
        case AccountType.DailyIncidentals:
            return t("dailyIncidentals");
        case AccountType.Other:
            return t("other");
        default:
            return "";
    }
}

export const getExpenseClaimPaymentLabel = (t: TFunction, payment: ExpensePayment) => {
    switch (payment) {
        case ExpensePayment.CompanyFunds:
            return t("companyFunds");
        case ExpensePayment.PersonalFunds:
            return t("personalFunds");
        default:
            return "";
    }
}

export const getDistanceUnitLabel = (t: TFunction, unit: DistanceUnit) => {
    switch (unit) {
        case DistanceUnit.KM:
            return t("km");
        case DistanceUnit.Mile:
            return t("mile");
        default:
            return "";
    }
}

export const getMileageRateLabel = (t: TFunction, e: MileageAllowance) => {
    const currency = getUserInfo()?.currency;

    const unit = getDistanceUnitLabel(t, e.unit);
    const rate = getCurrencySymbol(currency) + displayDecimal(e.rate);
    const secondaryRate = getCurrencySymbol(currency) + displayDecimal(e.secondaryRate);

    const variableRate = e.distance > 0;

    return variableRate ? t("mileageRateSecondary", { unit, rate, secondaryRate, distance: e.distance }) : t("mileageRate", { unit, rate });
}

export const getEnumValues = (enumerable: any) => Object.keys(enumerable).map(key => enumerable[key]).filter(v => typeof v === "number");

export const mapEnum = (enumerable: any, fn: Function): any[] => getEnumValues(enumerable).map(m => fn(m));

export const mapDict = (dict: Record<string, string | number>, fn: Function): any[] => {
    return Object.keys(dict).map((key, index) => fn(key, dict[key]));
}

export const getRandomInt = (min: number = -99999, max: number = -1) => {
    return Math.floor(Math.random() * (max - min) + min); //max is exclusive,  min is inclusive
}

export const getRandomDigits = (digits: number) => {
    const multiplier = 10 ** (digits - 1);
    return Math.floor(multiplier + Math.random() * 9 * multiplier);
}

export const shuffleArray = (arr: Array<any>) => {
    const array = [...arr];

    var currentIndex = array.length, temporaryValue, randomIndex;

    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}


const getBrowserName = () => {
    let bName = "unknown";
    const ua = navigator.userAgent;

    // The order matters here
    if (ua.indexOf("Firefox") > -1) {
        bName = "Mozilla Firefox";
    } else if (ua.indexOf("SamsungBrowser") > -1) {
        bName = "Samsung Internet";
    } else if (ua.indexOf("Opera") > -1 || ua.indexOf("OPR") > -1) {
        bName = "Opera";
    } else if (ua.indexOf("Trident") > -1) {
        bName = "Internet Explorer";
    } else if (ua.indexOf("Edge") > -1) {
        bName = "Microsoft Edge";
    } else if (ua.indexOf("Chrome") > -1) {
        bName = "Google Chrome";
    } else if (ua.indexOf("Safari") > -1) {
        bName = "Apple Safari";
    }

    return bName;
};

export const getDeviceInfo = () => ({
    mobile: isMobileMode(),
    language: navigator.language,
    platform: navigator.userAgentData?.platform ?? navigator.platform,
    browser: getBrowserName()
});


/**
     * Get unique elements in array by property value.
     * @param arr Array of objects.
     * @param comp Property name to use for comparison.
     */
export const uniqueBy = (arr: Array<any>, comp: string) => {
    if (!arr || arr.length === 0) return [];

    const seen = new Set();

    return arr.filter(el => {
        const duplicate = seen.has(el[comp]);
        seen.add(el[comp]);
        return !duplicate;
    });
}

export const unique = (arr: Array<any>) => {
    if (!arr || arr.length === 0) return [];

    const seen = new Set();

    return arr.filter(el => {
        const duplicate = seen.has(el);
        seen.add(el);
        return !duplicate;
    });
}

export const isEmpty = (obj: object) => !obj || !Object.keys(obj).length;

export const intersect = (arr1: number[], arr2: number[]) => arr1.some(e => arr2.includes(e));

export function groupBy<T>(collection: T[], iteratee: PropertyKey) {
    return collection.reduce((result, value) => {
        const key: PropertyKey = value[iteratee];
        if (result.hasOwnProperty(key)) {
            result[key].push(value);
        } else {
            result[key] = [value];
        }
        return result;
    }, {})
}

const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

export const downloadFileData = (data, filename, mime = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") => {
    const dataArr = base64ToArrayBuffer(data);
    const blob = new Blob([dataArr], { type: mime });

    const blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }, 200)
}

export const downloadFileUrl = (url, filename) => {
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = url;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
        document.body.removeChild(tempLink);
    }, 200)
}

export const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes == 0) return '0 Bytes';

    const k = 1024,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}

export const displayDecimal = (num: number) => num?.toLocaleString('en-US', {
    maximumFractionDigits: 2
});

export const displayMoney = (num: number, currency: Currency, useCode: boolean = false) => {
    if (typeof num !== "number") num = 0;

    return `${getCurrencySymbol(currency, useCode)} ${displayDecimal(num)}`;
};

export const getDefaultPath = () => {
    return isAuthenticated() ? "/claims" : "/login";
}

export const sum = (arr: number[]) => {
    if (!arr) return 0;

    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
        sum += arr[i];
    }
    return sum;
}


export const isEmptyAddress = (e: Place) => {
    if (!e) return true;

    return !e.unit && !e.street && !e.city && !e.province && !e.postalCode && !e.country;
}

export const addressesEqual = (a1: Place, a2: Place) => {
    if (!a1 && !a2) return true;
    if (!a1 || !a2) return false;

    return a1.unit === a2.unit &&
        a1.street === a2.street &&
        a1.city === a2.city &&
        a1.province === a2.province &&
        a1.postalCode === a2.postalCode &&
        a1.country === a2.country;
}

export const range = (start: number, stop: number, step: number = 1) => {
    return Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );
}
