import { PermissionEnum, User } from '../models'
import ajax from './ajax';
import { intersect } from './utils';

const USER_TOKEN = 'USER_TOKEN';
const TOKEN_EXPIRATION = 'TOKEN_EXPIRATION';
const USER_INFO = 'USER_INFO';

export const isAuthenticated = () => {
    return getToken() !== null;
};

export const authenticationExpired = () => {
    const expirationStr = localStorage.getItem(TOKEN_EXPIRATION);
    if(expirationStr){
        const expirationTime = Number.parseInt(expirationStr);
        if(expirationTime <= Date.now()) return true;  
    }

    return false;
};

export const getToken = () => {
    return localStorage.getItem(USER_TOKEN);
};

export const login = (token: string, user: User, rememberMe: boolean) => {
    const dayMillis = 24 * 3600000;
    const expiration = (Date.now() + (rememberMe ? dayMillis * 7 : dayMillis)).toString();

    localStorage.setItem(USER_TOKEN, token);
    localStorage.setItem(TOKEN_EXPIRATION, expiration);
    setUserInfo(user);
};

export const logout = () => {
    ajax.post('users/logout', {});
    setUserInfo(undefined);
    localStorage.removeItem(USER_TOKEN);
    localStorage.removeItem(TOKEN_EXPIRATION);
};

let _userInfo: User;

export const hasPermission = (permission: PermissionEnum) => {
    const permissions = getUserInfo()?.permissions;
    if (!permissions) return false;

    return permissions.includes(permission);
};

export const hasPermissions = (permissions: PermissionEnum[]) => {
    const userPermissions = getUserInfo()?.permissions;
    if (!userPermissions) return false;

    return intersect(userPermissions, permissions);
};

export const setUserInfo = (userinfo: User) => {
    if(userinfo) localStorage.setItem(USER_INFO, JSON.stringify(userinfo));
    else localStorage.removeItem(USER_INFO);

    _userInfo = userinfo;
}
export const getUserInfo = () => {
    if(!_userInfo) _userInfo = JSON.parse(localStorage.getItem(USER_INFO));

    return _userInfo;
}