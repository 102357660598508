import { Currency } from "src/models";
import { getUserInfo } from "./auth";

export const getCountryCode = (country: string = "") => {
    if (!country) country = getUserInfo()?.country;

    return countryCodes[country?.toLowerCase()];
};

export const getCurrency = (country: string = "") => {
    const countryCode = getCountryCode(country);
    if (!countryCode) return "USD";

    return countryCurrencies[countryCode];
}

export const getCurrencySymbol = (currency: Currency, useCode: boolean = false) => {
    if(currency === null) currency = getUserInfo()?.currency!;
    return useCode ? Currency[currency] : currencySymbols[Currency[currency]];
}

export const getCurrencySymbolByCountry = (country: string = "") => {
    const currency = getCurrency(country);
    if (!currency) return "$";

    return currencySymbols[currency];
}

type Country = keyof typeof countryCodes;

export const SUPPORTED_COUNTRIES: Country[] = ["pakistan", "bangladesh", "canada", "saudi arabia", "united arab emirates", "indonesia", "united states"];

export const PAYMENT_COUNTRIES: Country[] = ["canada", "saudi arabia", "united arab emirates", "united states"];

const countryCodes = {
    "afghanistan": "AF",
    "albania": "AL",
    "algeria": "DZ",
    "american samoa": "AS",
    "andorra": "AD",
    "angola": "AO",
    "anguilla": "AI",
    "antigua and barbuda": "AG",
    "argentina": "AR",
    "armenia": "AM",
    "aruba": "AW",
    "australia": "AU",
    "austria": "AT",
    "azerbaijan": "AZ",
    "the bahamas": "BS",
    "bahrain": "BH",
    "bangladesh": "BD",
    "barbados": "BB",
    "belarus": "BY",
    "belgium": "BE",
    "belize": "BZ",
    "benin": "BJ",
    "bermuda": "BM",
    "bhutan": "BT",
    "bolivia": "BO",
    "bosnia and herzegovina": "BA",
    "botswana": "BW",
    "brazil": "BR",
    "british virgin islands": "VG",
    "brunei": "BN",
    "bulgaria": "BG",
    "burkina faso": "BF",
    "burundi": "BI",
    "cambodia": "KH",
    "cameroon": "CM",
    "canada": "CA",
    "cape verde": "CV",
    "cayman islands": "KY",
    "central african republic": "CF",
    "chad": "TD",
    "chile": "CL",
    "china": "CN",
    "christmas island": "CX",
    "cocos (keeling) islands": "CC",
    "colombia": "CO",
    "comoros": "KM",
    "republic of the congo": "CG",
    "cook islands": "CK",
    "costa rica": "CR",
    "cote d'ivoire": "CI",
    "croatia": "HR",
    "cuba": "CU",
    "cyprus": "CY",
    "czech republic": "CZ",
    "denmark": "DK",
    "djibouti": "DJ",
    "dominica": "DM",
    "dominican republic": "DO",
    "ecuador": "EC",
    "egypt": "EG",
    "el salvador": "SV",
    "equatorial guinea": "GQ",
    "eritrea": "ER",
    "estonia": "EE",
    "ethiopia": "ET",
    "falkland islands (islas malvinas)": "FK",
    "faroe islands": "FO",
    "fiji": "FJ",
    "finland": "FI",
    "france": "FR",
    "french guiana": "GF",
    "french polynesia": "PF",
    "gabon": "GA",
    "the gambia": "GM",
    "georgia": "GE",
    "germany": "DE",
    "ghana": "GH",
    "gibraltar": "GI",
    "greece": "GR",
    "greenland": "GL",
    "grenada": "GD",
    "guadeloupe": "GP",
    "guam": "GU",
    "guatemala": "GT",
    "guinea": "GN",
    "guinea-bissau": "GW",
    "guyana": "GY",
    "haiti": "HT",
    "holy see (vatican city)": "VA",
    "honduras": "HN",
    "hungary": "HU",
    "iceland": "IS",
    "india": "IN",
    "indonesia": "ID",
    "iran": "IR",
    "iraq": "IQ",
    "ireland": "IE",
    "italy": "IT",
    "jamaica": "JM",
    "japan": "JP",
    "jordan": "JO",
    "kazakhstan": "KZ",
    "kenya": "KE",
    "kiribati": "KI",
    "north korea": "KP",
    "south korea": "KR",
    "kuwait": "KW",
    "kyrgyzstan": "KG",
    "laos": "LA",
    "latvia": "LV",
    "lebanon": "LB",
    "lesotho": "LS",
    "liberia": "LR",
    "libya": "LY",
    "liechtenstein": "LI",
    "lithuania": "LT",
    "luxembourg": "LU",
    "north macedonia": "MK",
    "madagascar": "MG",
    "malawi": "MW",
    "malaysia": "MY",
    "maldives": "MV",
    "mali": "ML",
    "malta": "MT",
    "isle of man": "IM",
    "marshall islands": "MH",
    "martinique": "MQ",
    "mauritania": "MR",
    "mauritius": "MU",
    "mayotte": "YT",
    "mexico": "MX",
    "federated states of micronesia": "FM",
    "moldova": "MD",
    "monaco": "MC",
    "mongolia": "MN",
    "montserrat": "MS",
    "morocco": "MA",
    "mozambique": "MZ",
    "myanmar (burma)": "MM",
    "namibia": "NA",
    "nauru": "NR",
    "nepal": "NP",
    "netherlands": "NL",
    "new caledonia": "NC",
    "new zealand": "NZ",
    "nicaragua": "NI",
    "niger": "NE",
    "nigeria": "NG",
    "niue": "NU",
    "norfolk island": "NF",
    "northern mariana islands": "MP",
    "norway": "NO",
    "oman": "OM",
    "pakistan": "PK",
    "palau": "PW",
    "palestinian territory": "PS",
    "panama": "PA",
    "papua new guinea": "PG",
    "paraguay": "PY",
    "peru": "PE",
    "philippines": "PH",
    "poland": "PL",
    "portugal": "PT",
    "puerto rico": "PR",
    "qatar": "QA",
    "reunion": "RE",
    "romania": "RO",
    "russia": "RU",
    "rwanda": "RW",
    "saint kitts and nevis": "KN",
    "saint lucia": "LC",
    "saint pierre and miquelon": "PM",
    "saint vincent and the grenadines": "VC",
    "san marino": "SM",
    "sao tome and principe": "ST",
    "saudi arabia": "SA",
    "senegal": "SN",
    "seychelles": "SC",
    "sierra leone": "SL",
    "singapore": "SG",
    "slovakia": "SK",
    "slovenia": "SI",
    "solomon islands": "SB",
    "somalia": "SO",
    "south africa": "ZA",
    "spain": "ES",
    "sri lanka": "LK",
    "sudan": "SD",
    "suriname": "SR",
    "svalbard": "SJ",
    "eswatini": "SZ",
    "sweden": "SE",
    "switzerland": "CH",
    "syria": "SY",
    "taiwan": "TW",
    "tajikistan": "TJ",
    "tanzania": "TZ",
    "thailand": "TH",
    "togo": "TG",
    "tokelau": "TK",
    "tonga": "TO",
    "trinidad and tobago": "TT",
    "tunisia": "TN",
    "turkey": "TR",
    "turkmenistan": "TM",
    "turks and caicos islands": "TC",
    "tuvalu": "TV",
    "uganda": "UG",
    "ukraine": "UA",
    "united arab emirates": "AE",
    "united kingdom": "GB",
    "united states": "US",
    "uruguay": "UY",
    "uzbekistan": "UZ",
    "vanuatu": "VU",
    "venezuela": "VE",
    "vietnam": "VN",
    "virgin islands": "VI",
    "wallis and futuna": "WF",
    "western sahara": "EH",
    "western samoa": "WS",
    "yemen": "YE",
    "democratic republic of the congo": "CD",
    "zambia": "ZM",
    "zimbabwe": "ZW",
    "hong kong": "HK",
    "macau": "MO",
    "british indian ocean territory": "IO",
    "saint helena": "SH",
    "guernsey": "GG",
    "serbia": "RS",
    "saint barthélemy": "BL",
    "montenegro": "ME",
    "jersey": "JE",
    "curaçao": "CW",
    "saint martin": "MF",
    "sint maarten": "SX",
    "timor-leste": "TL",
    "south sudan": "SS",
    "åland islands": "AX",
    "bonaire": "BQ",
    "republic of kosovo": "XK"
};

const countryCurrencies = {
    AD: 'EUR',
    AE: 'AED',
    AF: 'AFN',
    AG: 'XCD',
    AI: 'XCD',
    AL: 'ALL',
    AM: 'AMD',
    AO: 'AOA',
    AR: 'ARS',
    AS: 'USD',
    AT: 'EUR',
    AU: 'AUD',
    AW: 'AWG',
    AX: 'EUR',
    AZ: 'AZN',
    BA: 'BAM',
    BB: 'BBD',
    BD: 'BDT',
    BE: 'EUR',
    BF: 'XOF',
    BG: 'BGN',
    BH: 'BHD',
    BI: 'BIF',
    BJ: 'XOF',
    BL: 'EUR',
    BM: 'BMD',
    BN: 'BND',
    BO: 'BOB',
    BQ: 'USD',
    BR: 'BRL',
    BS: 'BSD',
    BT: 'BTN',
    BV: 'NOK',
    BW: 'BWP',
    BY: 'BYN',
    BZ: 'BZD',
    CA: 'CAD',
    CC: 'AUD',
    CD: 'CDF',
    CF: 'XAF',
    CG: 'CDF',
    CH: 'CHF',
    CI: 'XOF',
    CK: 'NZD',
    CL: 'CLF',
    CM: 'XAF',
    CN: 'CNY',
    CO: 'COP',
    CR: 'CRC',
    CU: 'CUC',
    CV: 'CVE',
    CW: 'ANG',
    CX: 'AUD',
    CY: 'EUR',
    CZ: 'CZK',
    DE: 'EUR',
    DJ: 'DJF',
    DK: 'DKK',
    DM: 'DOP',
    DO: 'DOP',
    DZ: 'DZD',
    EC: 'USD',
    EE: 'EUR',
    EG: 'EGP',
    EH: 'MAD',
    ER: 'ERN',
    ES: 'EUR',
    ET: 'ETB',
    FI: 'EUR',
    FJ: 'FJD',
    FM: 'USD',
    FO: 'DKK',
    FR: 'EUR',
    GA: 'XAF',
    GB: 'GBP',
    GD: 'XCD',
    GE: 'GEL',
    GF: 'EUR',
    GG: 'GBP',
    GH: 'GHS',
    GI: 'GIP',
    GL: 'DKK',
    GM: 'GMD',
    GN: 'GNF',
    GP: 'EUR',
    GQ: 'XAF',
    GR: 'EUR',
    GT: 'GTQ',
    GU: 'USD',
    GW: 'XOF',
    GY: 'GYD',
    HK: 'HKD',
    HN: 'HNL',
    HR: 'HRK',
    HT: 'HTG',
    HU: 'HUF',
    ID: 'IDR',
    IE: 'EUR',
    IL: 'ILS',
    IM: 'GBP',
    IN: 'INR',
    IO: 'USD',
    IQ: 'IQD',
    IR: 'IRR',
    IS: 'ISK',
    IT: 'EUR',
    JE: 'GBP',
    JM: 'JMD',
    JO: 'JOD',
    JP: 'JPY',
    KE: 'KES',
    KG: 'KGS',
    KH: 'KHR',
    KI: 'AUD',
    KM: 'KMF',
    KN: 'XCD',
    KP: 'KPW',
    KR: 'KRW',
    KW: 'KWD',
    KY: 'KYD',
    KZ: 'KZT',
    LB: 'LBP',
    LC: 'XCD',
    LI: 'CHF',
    LK: 'LKR',
    LR: 'LRD',
    LS: 'LSL',
    LT: 'EUR',
    LU: 'EUR',
    LV: 'EUR',
    LY: 'LYD',
    MA: 'MAD',
    MC: 'EUR',
    MD: 'MDL',
    ME: 'EUR',
    MF: 'EUR',
    MG: 'MGA',
    MH: 'USD',
    ML: 'XOF',
    MM: 'MMK',
    MN: 'MNT',
    MO: 'MOP',
    MP: 'USD',
    MQ: 'EUR',
    MR: 'MRU',
    MS: 'XCD',
    MT: 'EUR',
    MU: 'MUR',
    MV: 'MVR',
    MW: 'MWK',
    MX: 'MXN',
    MY: 'MYR',
    MZ: 'MZN',
    NA: 'NAD',
    NC: 'XPF',
    NE: 'NGN',
    NF: 'AUD',
    NG: 'NGN',
    NI: 'NIO',
    NL: 'EUR',
    NO: 'NOK',
    NP: 'NPR',
    NR: 'AUD',
    NU: 'NZD',
    NZ: 'NZD',
    OM: 'OMR',
    PA: 'PAB',
    PE: 'PEN',
    PF: 'XPF',
    PG: 'PGK',
    PH: 'PHP',
    PK: 'PKR',
    PL: 'PLN',
    PM: 'EUR',
    PN: 'NZD',
    PR: 'USD',
    PT: 'EUR',
    PW: 'USD',
    PY: 'PYG',
    QA: 'QAR',
    RE: 'EUR',
    RO: 'RON',
    RS: 'RSD',
    RU: 'RUB',
    RW: 'RWF',
    SA: 'SAR',
    SB: 'SBD',
    SC: 'SCR',
    SD: 'SDG',
    SE: 'SEK',
    SG: 'SGD',
    SH: 'SHP',
    SI: 'EUR',
    SJ: 'NOK',
    SK: 'EUR',
    SL: 'SLL',
    SM: 'EUR',
    SN: 'XOF',
    SO: 'SOS',
    SR: 'SRD',
    SS: 'SSP',
    ST: 'STN',
    SV: 'SVC',
    SX: 'ANG',
    SY: 'SYP',
    SZ: 'SZL',
    TC: 'USD',
    TD: 'XAF',
    TF: 'EUR',
    TG: 'XOF',
    TH: 'THB',
    TJ: 'TJS',
    TK: 'NZD',
    TL: 'USD',
    TM: 'TMT',
    TN: 'TND',
    TO: 'TOP',
    TR: 'TRY',
    TT: 'TTD',
    TV: 'AUD',
    TW: 'TWD',
    TZ: 'TZS',
    UA: 'UAH',
    UG: 'UGX',
    UM: 'USD',
    US: 'USD',
    UY: 'UYI',
    UZ: 'UZS',
    VA: 'EUR',
    VC: 'XCD',
    VE: 'VES',
    VG: 'USD',
    VI: 'USD',
    VN: 'VND',
    VU: 'VUV',
    WF: 'XPF',
    WS: 'USD',
    YE: 'YER',
    YT: 'EUR',
    ZA: 'ZAR',
    ZM: 'ZMW',
    ZW: 'ZWL',
    HM: 'AUD',
    FK: 'FKP',
    GS: 'GEL',
    LA: 'LAK',
    MK: 'MKD',
    AQ: 'USD',
    PS: 'ILS'
};

const currencySymbols = {
    ALL: 'L',
    AMD: '֏',
    ANG: 'ƒ',
    AOA: 'Kz',
    ARS: '$',
    AUD: '$',
    AWG: 'ƒ',
    AZN: '₼',
    BAM: 'KM',
    BBD: '$',
    BDT: '৳',
    BGN: 'лв',
    BIF: 'FBu',
    BMD: '$',
    BND: '$',
    BOB: '$b',
    BOV: 'BOV',
    BRL: 'R$',
    BSD: '$',
    BTC: '₿',
    BTN: 'Nu.',
    BWP: 'P',
    BYN: 'Br',
    BYR: 'Br',
    BZD: 'BZ$',
    CAD: '$',
    CDF: 'FC',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: '$',
    CNY: '¥',
    COP: '$',
    COU: 'COU',
    CRC: '₡',
    CUC: '$',
    CUP: '₱',
    CVE: '$',
    CZK: 'Kč',
    DJF: 'Fdj',
    DKK: 'kr',
    DOP: 'RD$',
    EEK: 'kr',
    EGP: '£',
    ERN: 'Nfk',
    ETB: 'Br',
    ETH: 'Ξ',
    EUR: '€',
    FJD: '$',
    FKP: '£',
    GBP: '£',
    GEL: '₾',
    GGP: '£',
    GHC: '₵',
    GHS: 'GH₵',
    GIP: '£',
    GMD: 'D',
    GNF: 'FG',
    GTQ: 'Q',
    GYD: '$',
    HKD: '$',
    HNL: 'L',
    HRK: 'kn',
    HTG: 'G',
    HUF: 'Ft',
    IDR: 'Rp',
    ILS: '₪',
    IMP: '£',
    INR: '₹',
    ISK: 'kr',
    JEP: '£',
    JMD: 'J$',
    JOD: 'JD',
    JPY: '¥',
    KES: 'KSh',
    KMF: 'CF',
    KPW: '₩',
    KRW: '₩',
    KWD: 'KD',
    KYD: '$',
    LAK: '₭',
    LBP: '£',
    LKR: '₨',
    LRD: '$',
    LSL: 'M',
    LTL: 'Lt',
    LVL: 'Ls',
    LYD: 'LD',
    MAD: 'MAD',
    MDL: 'lei',
    MGA: 'Ar',
    MMK: 'K',
    MOP: 'MOP$',
    MRO: 'UM',
    MRU: 'UM',
    MUR: '₨',
    MVR: 'Rf',
    MWK: 'MK',
    MXN: '$',
    MXV: 'MXV',
    MYR: 'RM',
    MZN: 'MT',
    NAD: '$',
    NGN: '₦',
    NIO: 'C$',
    NOK: 'kr',
    NPR: '₨',
    NZD: '$',
    OMR: '﷼',
    PAB: 'B/.',
    PEN: 'S/.',
    PGK: 'K',
    PHP: '₱',
    PKR: '₨',
    PLN: 'zł',
    PYG: 'Gs',
    RMB: '￥',
    RON: 'lei',
    RUB: '₽',
    RWF: 'R₣',
    SBD: '$',
    SCR: '₨',
    SEK: 'kr',
    SGD: 'S$',
    SHP: '£',
    SLL: 'Le',
    SOS: 'S',
    SRD: '$',
    SSP: '£',
    STD: 'Db',
    STN: 'Db',
    SVC: '$',
    SYP: '£',
    SZL: 'E',
    THB: '฿',
    TJS: 'SM',
    TMT: 'T',
    TOP: 'T$',
    TRL: '₤',
    TRY: '₺',
    TTD: 'TT$',
    TVD: '$',
    TWD: 'NT$',
    TZS: 'TSh',
    UAH: '₴',
    UGX: 'USh',
    USD: '$',
    UYI: 'UYI',
    UYU: '$U',
    UYW: 'UYW',
    VEF: 'Bs',
    VES: 'Bs.S',
    VND: '₫',
    VUV: 'VT',
    WST: 'WS$',
    XAF: 'FCFA',
    XBT: 'Ƀ',
    XCD: '$',
    XOF: 'CFA',
    XPF: '₣',
    XSU: 'Sucre',
    XUA: 'XUA',
    ZAR: 'R',
    ZMW: 'ZK',
    ZWD: 'Z$',
    ZWL: '$',
    SAR: 'SAR',
    AED: 'AED'
};