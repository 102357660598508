import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider, extendTheme, withDefaultColorScheme } from "@chakra-ui/react"
import { QueryClient, QueryClientProvider } from 'react-query'
import { GoogleReCaptchaProvider } from './components/google-recaptcha';
import PrivateRoute from "./components/PrivateRoute";
import Splash from "./components/Splash";
import { mode } from '@chakra-ui/theme-tools'

const AccessDenied = lazy(() => import("./pages/AccessDenied"));
const Home = lazy(() => import("./pages/Home"));
const Signup = lazy(() => import("./pages/Signup"));
const AddCompany = lazy(() => import("./pages/AddCompany"));
const Login = lazy(() => import("./pages/Login"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Attendance = lazy(() => import("./pages/Attendance"));
const Users = lazy(() => import("./pages/Users"));
const ImportUsers = lazy(() => import("./pages/ImportUsers"));
const NewUser = lazy(() => import("./pages/NewUser"));
const EditUser = lazy(() => import("./pages/EditUser"));
const Companies = lazy(() => import("./pages/Companies"));
const EditCompany = lazy(() => import("./pages/EditCompany"));
const Profile = lazy(() => import("./pages/Profile"));
const CompanyProfile = lazy(() => import("./pages/CompanyProfile"));
const Settings = lazy(() => import("./pages/Settings"));

const Timesheets = lazy(() => import("./pages/Timesheets"));
const Timesheet = lazy(() => import("./pages/Timesheet"));
const Projects = lazy(() => import("./pages/Projects"));
const Subscriptions = lazy(() => import("./pages/Subscriptions"));
const Discounts = lazy(() => import("./pages/Discounts"));
const Invoices = lazy(() => import("./pages/Invoices"));
const ExpenseClaims = lazy(() => import("./pages/ExpenseClaims"));
const EditExpenseClaim = lazy(() => import("./pages/EditExpenseClaim"));
const ExpenseClaimsReport = lazy(() => import("./pages/ExpenseClaimsReport"));

const queryClient = new QueryClient();

const theme = extendTheme(
    withDefaultColorScheme({ colorScheme: 'blue', components: ['Button', 'Switch', 'Badge'], }),
    {

        components: {
            FormLabel: {
                baseStyle: {
                    color: "gray.500",
                }
            },
            Form: {
                baseStyle: (props) => ({
                    helperText: {
                    color: mode('gray.500', 'gray.300')(props),
                  },
                }),
              },
        },
    });


const App = () => (
    <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
            <GoogleReCaptchaProvider reCaptchaKey="6Lf8vpUpAAAAAH_Fs9fw4KK0Y0_JZM4m7Yw483om">
                <Suspense fallback={<Splash />}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/Login" element={<Login />} />
                            <Route path="/ResetPassword" element={<ResetPassword />} />
                            <Route path="/ForgotPassword" element={<ForgotPassword />} />

                            <Route path="/accessDenied" element={<AccessDenied />} />

                            <Route path="/signup" element={<Signup />} />
                            <Route path="/newcompany" element={<PrivateRoute><AddCompany /></PrivateRoute>} />
                            <Route path="/attendance" element={<PrivateRoute><Attendance /></PrivateRoute>} />
                            <Route path="/people" element={<PrivateRoute><Users /></PrivateRoute>} />
                            <Route path="/people/import" element={<PrivateRoute><ImportUsers /></PrivateRoute>} />
                            <Route path="/newmember" element={<PrivateRoute><NewUser /></PrivateRoute>} />
                            <Route path="/editmember" element={<PrivateRoute><EditUser /></PrivateRoute>} />
                            <Route path="/companies" element={<PrivateRoute><Companies /></PrivateRoute>} />
                            <Route path="/editcompany" element={<PrivateRoute><EditCompany /></PrivateRoute>} />
                            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                            <Route path="/company-profile" element={<PrivateRoute><CompanyProfile /></PrivateRoute>} />
                            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />

                            <Route path="/timesheets" element={<PrivateRoute><Timesheets /></PrivateRoute>} />
                            <Route path="/timesheet" element={<PrivateRoute><Timesheet /></PrivateRoute>} />
                            <Route path="/projects" element={<PrivateRoute><Projects /></PrivateRoute>} />
                            <Route path="/subscriptions" element={<PrivateRoute><Subscriptions /></PrivateRoute>} />
                            <Route path="/discounts" element={<PrivateRoute><Discounts /></PrivateRoute>} />
                            <Route path="/invoices" element={<PrivateRoute><Invoices /></PrivateRoute>} />
                            <Route path="/claims" element={<PrivateRoute><ExpenseClaims /></PrivateRoute>} />
                            <Route path="/editclaim" element={<PrivateRoute><EditExpenseClaim /></PrivateRoute>} />
                            <Route path="/claimsreport" element={<PrivateRoute><ExpenseClaimsReport /></PrivateRoute>} />
                                                        
                            <Route path="/*"  element={<Home />} />

                        </Routes>
                    </BrowserRouter>
                </Suspense>
            </GoogleReCaptchaProvider>
        </QueryClientProvider>
    </ChakraProvider>
);

export default App;
