import React from 'react';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { authenticationExpired, logout, isAuthenticated } from '../helpers/auth';
import { useQueryClient } from 'react-query';

export interface Props {
    children: JSX.Element,
}

const PrivateRoute = ({ children }: Props) => {
    const { t } = useTranslation();
    const toast = useToast();
    const queryClient = useQueryClient();

    if (isAuthenticated()) {
        if (authenticationExpired()) {
            toast({
                description: t("sessionExpired"),
                status: "warning",
                isClosable: true,
            });
            logout();

            queryClient.clear()
        } else {
            return children;
        }
    }

    return <Navigate to={`/login?returnUrl=${window.location.pathname}`} />;
}

export default PrivateRoute;