import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locale/en.json';
import pk from './locale/pk.json';

const LOCALIZATION_LANG = 'LOCALIZATION_LANG';

export const getStoredLang = () => {
  const lang = localStorage.getItem(LOCALIZATION_LANG);

  return lang ?? 'en';
};

export const storeLang = (lang: string) => {
  localStorage.setItem(LOCALIZATION_LANG, lang);
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: en },
      pk: { translations: pk }
    },
    lng: getStoredLang(),
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;